import React from 'react';
import { Code1, Monitor, Setting2 } from 'iconsax-react'; // Assuming you are using iconsax-react

const ServicesSection = () => {
  return (
    <div className="py-20 bg-white px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
      
      {/* Left Column - Our Services Intro */}
      <div className="flex flex-col justify-center space-y-6">
        <h2 className="text-4xl font-semibold">Our Services</h2>
        <p className="text-[18px] text-gray-600 max-w-md">
          We focus on providing comprehensive solutions for business growth, using the latest technologies and strategies to streamline operations and enhance user experiences.
        </p>
        <a
          href="#contact"
          className="mt-4 inline-block bg-[#FF6500] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#FF5500] transition duration-300 w-32 text-center"
        >
          Learn more
        </a>
      </div>
      
      {/* Right Column - Services */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16 w-full">
        
        {/* Application Development */}
        <div className="w-full sm:w-[90%] lg:w-[130%] mx-auto p-8 rounded-lg border-2 border-[#FF6500] hover:shadow-lg transition duration-300">
          <Code1 className="text-6xl text-[#FF6500] mb-4" />
          <h3 className="text-xl font-semibold text-gray-900">Application Development</h3>
          <p className="mt-2 text-gray-600">
            Developing scalable and secure applications tailored to your business needs.
          </p>
        </div>

        {/* UI/UX Design - Standout Card */}
        <div className="w-full sm:w-[90%] lg:w-[130%] mx-auto p-8 rounded-lg bg-[#1E3E62] text-white hover:shadow-lg transition duration-300">
          <Monitor className="text-6xl text-white mb-4" />
          <h3 className="text-xl font-semibold">UI/UX Design</h3>
          <p className="mt-2">
            Crafting intuitive interfaces that deliver seamless user experiences.
          </p>
        </div>

        {/* CMS Solutions */}
        <div className="w-full sm:w-[90%] lg:w-[130%] mx-auto p-8 rounded-lg border-2 border-[#FF6500] hover:shadow-lg transition duration-300">
          <Setting2 className="text-6xl text-[#FF6500] mb-4" />
          <h3 className="text-xl font-semibold text-gray-900">CMS Solutions</h3>
          <p className="mt-2 text-gray-600">
            Simplifying content management with powerful and flexible CMS solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
