import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'; // React Router Link
import { Link as ScrollLink } from 'react-scroll'; // Scroll link for smooth scrolling
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../assets/FireLabs Logo PNG.png';
import { Transition } from '@headlessui/react';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50 bg-white shadow-md">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex items-center lg:flex-1">
          <RouterLink to="/" className="-m-1.5 p-1.5 flex items-center">
            <img className="h-8 w-auto" src={logo} alt="Company logo" />
            <span className="ml-3 text-lg font-bold text-[#0B192C]">Fire Labs</span>
          </RouterLink>
        </div>
        
        {/* Desktop Links */}
        <div className="hidden lg:flex lg:gap-x-12">
          <RouterLink to="/" className="text-sm font-semibold leading-6 text-[#1E3E62] hover:text-[#FF6500]">
            Home
          </RouterLink>
          <ScrollLink
            to="services"
            smooth={true}
            duration={500}
            offset={-70} // Offset for fixed navbar height
            className="text-sm font-semibold leading-6 text-[#1E3E62] hover:text-[#FF6500] cursor-pointer"
          >
            Our Services
          </ScrollLink>
          <ScrollLink
            to="tech"
            smooth={true}
            duration={500}
            offset={-70}
            className="text-sm font-semibold leading-6 text-[#1E3E62] hover:text-[#FF6500] cursor-pointer"
          >
            Tech Stack
          </ScrollLink>
        </div>

        {/* Contact Us Button */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <RouterLink to="/contact" className="rounded-md bg-[#FF6500] px-4 py-2 text-sm font-semibold text-white hover:bg-[#FF5500]">
            Contact Us
          </RouterLink>
        </div>

        {/* Mobile menu button */}
        <div className="lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <Bars3Icon className="h-6 w-6 text-[#0B192C]" aria-hidden="true" />
            <span className="sr-only">Open main menu</span>
          </button>
        </div>
      </nav>

      {/* Mobile menu with animation */}
      <Transition
        show={mobileMenuOpen}
        enter="transition ease-out duration-300 transform"
        enterFrom="translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transition ease-in duration-200 transform"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-full opacity-0"
      >
        <div className="fixed inset-0 z-50 bg-white p-6">
          <div className="flex items-center justify-between">
            <RouterLink to="/" className="-m-1.5 p-1.5 flex items-center">
              <img className="h-8 w-auto" src={logo} alt="Company logo" />
              <span className="ml-3 text-lg font-bold text-[#0B192C]">Fire Labs</span>
            </RouterLink>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <XMarkIcon className="h-6 w-6 text-[#0B192C]" aria-hidden="true" />
              <span className="sr-only">Close main menu</span>
            </button>
          </div>
          <div className="mt-6">
            <RouterLink
              to="/"
              className="block text-base font-semibold leading-7 text-[#1E3E62] hover:text-[#FF6500] py-2"
              onClick={() => setMobileMenuOpen(false)}
            >
              Home
            </RouterLink>
            <ScrollLink
              to="services"
              smooth={true}
              duration={500}
              offset={-70}
              className="block text-base font-semibold leading-7 text-[#1E3E62] hover:text-[#FF6500] py-2 cursor-pointer"
              onClick={() => setMobileMenuOpen(false)}
            >
              Our Services
            </ScrollLink>
            <ScrollLink
              to="tech"
              smooth={true}
              duration={500}
              offset={-70}
              className="block text-base font-semibold leading-7 text-[#1E3E62] hover:text-[#FF6500] py-2 cursor-pointer"
              onClick={() => setMobileMenuOpen(false)}
            >
              Tech Stack
            </ScrollLink>
          </div>
          <div className="mt-6">
            <RouterLink
              to="/contact"
              className="block w-full rounded-md bg-[#FF6500] px-4 py-2 text-base font-semibold leading-7 text-white text-center hover:bg-[#FF5500]"
              onClick={() => setMobileMenuOpen(false)}
            >
              Contact Us
            </RouterLink>
          </div>
        </div>
      </Transition>
    </header>
  );
};

export default Navbar;
