import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { ReactTyped } from 'react-typed'; // Correct named import for ReactTyped
import { Link as ScrollLink } from 'react-scroll'; // Import from react-scroll for smooth scrolling
import Navbar from '../components/Navbar'; 
import ServicesSection from '../components/ServicesSection'; 
import Tech from '../components/Tech';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="relative bg-white overflow-hidden min-h-screen">
      <Navbar /> {/* Navbar included here */}
      
      {/* Hero Section with Typing Animation and CTA Buttons */}
      <div id="hero" className="relative text-center px-4 sm:px-6 lg:px-8 mt-52">
        {/* Typing Animation */}
        <h1 className="text-5xl font-semibold text-gray-900 sm:text-4xl md:text-[32px] lg:text-[64px]">
          <ReactTyped
            strings={[
              'Custom Software Solutions',
              'Tailored for Your Business',
              'Empowering Innovation',
            ]}
            typeSpeed={40}
            backSpeed={50}
            loop
          />
        </h1>

        {/* Slogan */}
        <p className="mt-12 text-[18px] font-normal text-gray-600">
          Delivering modern technology solutions<br /> to solve complex business challenges and drive growth.
        </p>

        {/* CTA Buttons */}
        <div className="mt-12 flex justify-center space-x-4">
          <Link
            to="/contact"
            className="rounded-lg bg-[#FF6500] px-5 py-3 text-[14px] font-semibold text-white shadow-sm hover:bg-[#FF5500]"
          >
            Start Your Project
          </Link>
          <ScrollLink
            to="services"
            smooth={true}
            duration={500}
            offset={-70}  // Offset for fixed navbar height
            className="rounded-lg border border-[#FF6500] px-5 py-3 text-[14px] font-semibold text-[#FF6500] hover:text-white hover:bg-[#FF5500] cursor-pointer"
          >
            Learn More
          </ScrollLink>
        </div>
      </div>

      {/* Services Section */}
      <div id="services">
        <ServicesSection />
      </div>

      {/* Tech Stack Section */}
      <div id="tech">
        <Tech />
      </div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Home;
