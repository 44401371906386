import React from 'react';
import Navbar from '../components/Navbar'; // Adjust the path if necessary
import Footer from '../components/Footer'; // Adjust the path if necessary

const Contact = () => {
  return (
    <div>
      {/* Include Navbar */}
      <Navbar />

      {/* Main Contact Form Section */}
      <div className="bg-white py-20 px-6 sm:px-12 lg:px-24 max-w-4xl mx-auto mt-24">
        <h1 className="text-3xl font-semibold text-[#1E3E62] mb-6 text-center">Message us</h1>
        <p className="text-gray-600 mb-12 text-center">We’ll get back to you within 24 hours.</p>

        <form
          action="https://submit-form.com/htXZLHpz5"
          method="POST"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2"
        >
          {/* First Name */}
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="First name"
              required
            />
          </div>

          {/* Email */}
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="Email"
              required
            />
          </div>

          {/* Phone Number */}
          <div>
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="+65"
              required
            />
          </div>

          {/* Company Name */}
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="Company name"
              required
            />
          </div>

          {/* Project Name */}
          <div className="sm:col-span-2">
            <label htmlFor="projectName" className="block text-sm font-medium text-gray-700">Project name</label>
            <input
              type="text"
              id="projectName"
              name="projectName"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="Project name"
              required
            />
          </div>

          {/* Project Budget */}
          <div className="sm:col-span-2">
            <label htmlFor="projectBudget" className="block text-sm font-medium text-gray-700">Project budget</label>
            <input
              type="number"
              id="projectBudget"
              name="projectBudget"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              placeholder="Project budget (in SGD)"
              required
            />
          </div>

          {/* Message */}
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              id="message"
              name="message"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-[#FF6500]"
              rows="4"
              placeholder="Tell us about your project..."
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="sm:col-span-2">
            <button
              type="submit"
              className="w-full py-3 bg-[#FF6500] text-white font-semibold rounded-lg hover:bg-[#FF5500] transition duration-300"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>

      {/* Include Footer */}
      <Footer />
    </div>
  );
};

export default Contact;
