import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'; // Social Media Icons

const Footer = () => {
  return (
    <footer className="bg-[#1E3E62] py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row lg:justify-between gap-16">
          
          {/* Left Column - Company Info */}
          <div className="flex flex-col space-y-4 mb-8 lg:mb-0">
            <h3 className="text-lg font-semibold text-white">Fire Labs</h3>
            <p className="text-sm text-gray-300">
              We build custom software solutions that help businesses grow and succeed in the digital age.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-300 hover:text-white">
                <FaFacebookF />
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <FaTwitter />
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <FaInstagram />
              </a>
            </div>
            <p className="text-sm text-gray-400 mt-4">© 2024 Fire Labs. All rights reserved.</p>
          </div>

          {/* Sitemap - Columns */}
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-12">
            
            {/* Solutions Column */}
            <div>
              <h4 className="text-lg font-semibold text-white">Solutions</h4>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Application Development</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Web Applications</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">CMS Solutions</a></li>
              </ul>
            </div>

            {/* Support Column */}
            <div>
              <h4 className="text-lg font-semibold text-white">Support</h4>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Documentation</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">API Reference</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Guides</a></li>
              </ul>
            </div>

            {/* Company Column */}
            <div>
              <h4 className="text-lg font-semibold text-white">Company</h4>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">About</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Blog</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Careers</a></li>
              </ul>
            </div>

            {/* Legal Column */}
            <div>
              <h4 className="text-lg font-semibold text-white">Legal</h4>
              <ul className="mt-2 space-y-2">
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Privacy Policy</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Terms of Service</a></li>
                <li><a href="#" className="text-sm text-gray-300 hover:text-white">Cookie Policy</a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
