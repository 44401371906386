import React from 'react';
import { SiJavascript, SiReact, SiNodedotjs, SiMongodb, SiSocketdotio } from 'react-icons/si'; // React icons for tech stack

const Tech = () => {
  return (
    <div className="py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
      {/* Left Column - Tech Icons */}
      <div className="flex flex-wrap justify-center lg:justify-start space-x-8 space-y-4 lg:space-y-0">
        <div className="flex items-center justify-center w-16 h-16 bg-[#F0DB4F] rounded-full">
          <SiJavascript className="text-[4rem] text-white" />
        </div>

        {/* JavaScript Icon inside a circular background */}

        <div className="flex items-center justify-center w-16 h-16 shadow-lg rounded-full">
          <SiReact className="text-5xl text-[#61DBFB]" /> {/* React Icon */}
        </div>
        <div className="flex items-center justify-center w-16 h-16 shadow-lg rounded-full">
          <SiNodedotjs className="text-5xl text-[#68A063]" />{" "}
          {/* Node.js Icon */}
        </div>
        <div className="flex items-center justify-center w-16 h-16 shadow-lg rounded-full">
          <SiMongodb className="text-5xl text-[#4DB33D]" /> {/* MongoDB Icon */}
        </div>
        <div className="flex items-center justify-center w-16 h-16 shadow-lg rounded-full">
          <SiSocketdotio className="text-5xl text-[#010101]" />{" "}
          {/* Socket.io Icon */}
        </div>
      </div>

      {/* Right Column - Tech Stack Title and Description */}
      <div className="flex flex-col justify-center">
        <h2 className="text-4xl font-semibold ">
          Our Tech Stack
        </h2>
        <p className="mt-4 text-[18px] text-gray-600">
          We leverage modern technologies to develop robust and scalable
          applications. Our tech stack includes JavaScript for dynamic front-end
          and back-end development, React for user interfaces, Node.js and
          MongoDB for server-side applications and databases, and Socket.io for
          real-time communication.
        </p>
      </div>
    </div>
  );
};

export default Tech;
